exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-manufacturing-brochures-tsx": () => import("./../../../src/pages/manufacturing/brochures.tsx" /* webpackChunkName: "component---src-pages-manufacturing-brochures-tsx" */),
  "component---src-pages-manufacturing-cocoa-academy-contentful-event-slug-tsx": () => import("./../../../src/pages/manufacturing/cocoa-academy/{ContentfulEvent.slug}.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-academy-contentful-event-slug-tsx" */),
  "component---src-pages-manufacturing-cocoa-academy-tsx": () => import("./../../../src/pages/manufacturing/cocoa-academy.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-academy-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-black-and-dark-cocoa-powders-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/black-and-dark-cocoa-powders.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-black-and-dark-cocoa-powders-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-black-and-dark-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/black-and-dark.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-black-and-dark-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-butters-contentful-cocoa-butter-slug-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/butters/{ContentfulCocoaButter.slug}.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-butters-contentful-cocoa-butter-slug-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-butters-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/butters.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-butters-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-high-fat-cocoa-powders-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/high-fat-cocoa-powders.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-high-fat-cocoa-powders-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-liquors-contentful-cocoa-liquor-slug-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/liquors/{ContentfulCocoaLiquor.slug}.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-liquors-contentful-cocoa-liquor-slug-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-liquors-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/liquors.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-liquors-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-organic-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/organic.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-organic-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-powders-contentful-cocoa-powder-slug-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/powders/{ContentfulCocoaPowder.slug}.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-powders-contentful-cocoa-powder-slug-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-powders-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/powders.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-powders-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-segregated-cocoa-powders-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients/segregated-cocoa-powders.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-segregated-cocoa-powders-tsx" */),
  "component---src-pages-manufacturing-cocoa-ingredients-tsx": () => import("./../../../src/pages/manufacturing/cocoa-ingredients.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-ingredients-tsx" */),
  "component---src-pages-manufacturing-cocoa-insights-tsx": () => import("./../../../src/pages/manufacturing/cocoa-insights.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-insights-tsx" */),
  "component---src-pages-manufacturing-cocoa-inspiration-tsx": () => import("./../../../src/pages/manufacturing/cocoa-inspiration.tsx" /* webpackChunkName: "component---src-pages-manufacturing-cocoa-inspiration-tsx" */),
  "component---src-pages-manufacturing-contact-tsx": () => import("./../../../src/pages/manufacturing/contact.tsx" /* webpackChunkName: "component---src-pages-manufacturing-contact-tsx" */),
  "component---src-pages-manufacturing-history-tsx": () => import("./../../../src/pages/manufacturing/history.tsx" /* webpackChunkName: "component---src-pages-manufacturing-history-tsx" */),
  "component---src-pages-manufacturing-know-cocoa-application-tsx": () => import("./../../../src/pages/manufacturing/know-cocoa/application.tsx" /* webpackChunkName: "component---src-pages-manufacturing-know-cocoa-application-tsx" */),
  "component---src-pages-manufacturing-know-cocoa-cultivation-tsx": () => import("./../../../src/pages/manufacturing/know-cocoa/cultivation.tsx" /* webpackChunkName: "component---src-pages-manufacturing-know-cocoa-cultivation-tsx" */),
  "component---src-pages-manufacturing-know-cocoa-production-tsx": () => import("./../../../src/pages/manufacturing/know-cocoa/production.tsx" /* webpackChunkName: "component---src-pages-manufacturing-know-cocoa-production-tsx" */),
  "component---src-pages-manufacturing-know-cocoa-tsx": () => import("./../../../src/pages/manufacturing/know-cocoa.tsx" /* webpackChunkName: "component---src-pages-manufacturing-know-cocoa-tsx" */),
  "component---src-pages-manufacturing-magazine-tsx": () => import("./../../../src/pages/manufacturing/magazine.tsx" /* webpackChunkName: "component---src-pages-manufacturing-magazine-tsx" */),
  "component---src-pages-manufacturing-sustainability-cocoa-compass-tsx": () => import("./../../../src/pages/manufacturing/sustainability/cocoa-compass.tsx" /* webpackChunkName: "component---src-pages-manufacturing-sustainability-cocoa-compass-tsx" */),
  "component---src-pages-manufacturing-sustainability-tsx": () => import("./../../../src/pages/manufacturing/sustainability.tsx" /* webpackChunkName: "component---src-pages-manufacturing-sustainability-tsx" */),
  "component---src-pages-manufacturing-sustainability-vision-tsx": () => import("./../../../src/pages/manufacturing/sustainability/vision.tsx" /* webpackChunkName: "component---src-pages-manufacturing-sustainability-vision-tsx" */),
  "component---src-pages-ofi-tsx": () => import("./../../../src/pages/ofi.tsx" /* webpackChunkName: "component---src-pages-ofi-tsx" */),
  "component---src-pages-pro-chefs-contentful-chef-slug-tsx": () => import("./../../../src/pages/pro/chefs/{ContentfulChef.slug}.tsx" /* webpackChunkName: "component---src-pages-pro-chefs-contentful-chef-slug-tsx" */),
  "component---src-pages-pro-cocoa-ingredients-tsx": () => import("./../../../src/pages/pro/cocoa-ingredients.tsx" /* webpackChunkName: "component---src-pages-pro-cocoa-ingredients-tsx" */),
  "component---src-pages-pro-cocoa-powder-collection-tsx": () => import("./../../../src/pages/pro/cocoa-powder-collection.tsx" /* webpackChunkName: "component---src-pages-pro-cocoa-powder-collection-tsx" */),
  "component---src-pages-pro-contact-tsx": () => import("./../../../src/pages/pro/contact.tsx" /* webpackChunkName: "component---src-pages-pro-contact-tsx" */),
  "component---src-pages-pro-hot-cocoa-tsx": () => import("./../../../src/pages/pro/hot-cocoa.tsx" /* webpackChunkName: "component---src-pages-pro-hot-cocoa-tsx" */),
  "component---src-pages-pro-index-1-tsx": () => import("./../../../src/pages/pro/index-1.tsx" /* webpackChunkName: "component---src-pages-pro-index-1-tsx" */),
  "component---src-pages-pro-ingredients-artisan-black-tsx": () => import("./../../../src/pages/pro/ingredients/artisan-black.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-artisan-black-tsx" */),
  "component---src-pages-pro-ingredients-carbon-black-tsx": () => import("./../../../src/pages/pro/ingredients/carbon-black.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-carbon-black-tsx" */),
  "component---src-pages-pro-ingredients-crimson-red-tsx": () => import("./../../../src/pages/pro/ingredients/crimson-red.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-crimson-red-tsx" */),
  "component---src-pages-pro-ingredients-deep-russet-tsx": () => import("./../../../src/pages/pro/ingredients/deep-russet.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-deep-russet-tsx" */),
  "component---src-pages-pro-ingredients-hot-chocolate-tsx": () => import("./../../../src/pages/pro/ingredients/hot-chocolate.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-hot-chocolate-tsx" */),
  "component---src-pages-pro-ingredients-quito-oro-tsx": () => import("./../../../src/pages/pro/ingredients/quito-oro.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-quito-oro-tsx" */),
  "component---src-pages-pro-ingredients-rich-terracotta-tsx": () => import("./../../../src/pages/pro/ingredients/rich-terracotta.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-rich-terracotta-tsx" */),
  "component---src-pages-pro-ingredients-rose-blush-tsx": () => import("./../../../src/pages/pro/ingredients/rose-blush.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-rose-blush-tsx" */),
  "component---src-pages-pro-ingredients-stellar-tsx": () => import("./../../../src/pages/pro/ingredients/stellar.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-stellar-tsx" */),
  "component---src-pages-pro-ingredients-terra-rossa-tsx": () => import("./../../../src/pages/pro/ingredients/terra-rossa.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-terra-rossa-tsx" */),
  "component---src-pages-pro-ingredients-true-dark-tsx": () => import("./../../../src/pages/pro/ingredients/true-dark.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-true-dark-tsx" */),
  "component---src-pages-pro-ingredients-true-gold-tsx": () => import("./../../../src/pages/pro/ingredients/true-gold.tsx" /* webpackChunkName: "component---src-pages-pro-ingredients-true-gold-tsx" */),
  "component---src-pages-pro-know-cocoa-cocoa-powder-in-cooking-tsx": () => import("./../../../src/pages/pro/know-cocoa/cocoa-powder-in-cooking.tsx" /* webpackChunkName: "component---src-pages-pro-know-cocoa-cocoa-powder-in-cooking-tsx" */),
  "component---src-pages-pro-know-cocoa-growing-cocoa-tsx": () => import("./../../../src/pages/pro/know-cocoa/growing-cocoa.tsx" /* webpackChunkName: "component---src-pages-pro-know-cocoa-growing-cocoa-tsx" */),
  "component---src-pages-pro-know-cocoa-manufacturing-cocoa-tsx": () => import("./../../../src/pages/pro/know-cocoa/manufacturing-cocoa.tsx" /* webpackChunkName: "component---src-pages-pro-know-cocoa-manufacturing-cocoa-tsx" */),
  "component---src-pages-pro-know-cocoa-tsx": () => import("./../../../src/pages/pro/know-cocoa.tsx" /* webpackChunkName: "component---src-pages-pro-know-cocoa-tsx" */),
  "component---src-pages-pro-know-cocoa-video-series-tsx": () => import("./../../../src/pages/pro/know-cocoa/video-series.tsx" /* webpackChunkName: "component---src-pages-pro-know-cocoa-video-series-tsx" */),
  "component---src-pages-pro-natural-and-dutched-cocoa-powder-tsx": () => import("./../../../src/pages/pro/natural-and-dutched-cocoa-powder.tsx" /* webpackChunkName: "component---src-pages-pro-natural-and-dutched-cocoa-powder-tsx" */),
  "component---src-pages-pro-pastry-chefs-tsx": () => import("./../../../src/pages/pro/pastry-chefs.tsx" /* webpackChunkName: "component---src-pages-pro-pastry-chefs-tsx" */),
  "component---src-pages-pro-post-contentful-page-slug-tsx": () => import("./../../../src/pages/pro/post/{ContentfulPage.slug}.tsx" /* webpackChunkName: "component---src-pages-pro-post-contentful-page-slug-tsx" */),
  "component---src-pages-pro-recipe-book-tsx": () => import("./../../../src/pages/pro/recipe-book.tsx" /* webpackChunkName: "component---src-pages-pro-recipe-book-tsx" */),
  "component---src-pages-pro-recipes-contentful-recipe-slug-tsx": () => import("./../../../src/pages/pro/recipes/{ContentfulRecipe.slug}.tsx" /* webpackChunkName: "component---src-pages-pro-recipes-contentful-recipe-slug-tsx" */),
  "component---src-pages-pro-recipes-tsx": () => import("./../../../src/pages/pro/recipes.tsx" /* webpackChunkName: "component---src-pages-pro-recipes-tsx" */),
  "component---src-pages-pro-story-tsx": () => import("./../../../src/pages/pro/story.tsx" /* webpackChunkName: "component---src-pages-pro-story-tsx" */),
  "component---src-pages-pro-sustainability-tsx": () => import("./../../../src/pages/pro/sustainability.tsx" /* webpackChunkName: "component---src-pages-pro-sustainability-tsx" */),
  "component---src-pages-pro-trace-tsx": () => import("./../../../src/pages/pro/trace.tsx" /* webpackChunkName: "component---src-pages-pro-trace-tsx" */),
  "component---src-pages-pro-tsx": () => import("./../../../src/pages/pro.tsx" /* webpackChunkName: "component---src-pages-pro-tsx" */),
  "component---src-pages-quito-contentful-supply-page-batch-number-tsx": () => import("./../../../src/pages/quito/{ContentfulSupplyPage.batchNumber}.tsx" /* webpackChunkName: "component---src-pages-quito-contentful-supply-page-batch-number-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */),
  "component---src-pages-video-tsx": () => import("./../../../src/pages/video.tsx" /* webpackChunkName: "component---src-pages-video-tsx" */),
  "slice---src-components-slices-cocoa-insights-slice-tsx": () => import("./../../../src/components/slices/CocoaInsightsSlice.tsx" /* webpackChunkName: "slice---src-components-slices-cocoa-insights-slice-tsx" */),
  "slice---src-components-slices-elements-maps-map-section-slice-tsx": () => import("./../../../src/components/slices/elements/maps/MapSectionSlice.tsx" /* webpackChunkName: "slice---src-components-slices-elements-maps-map-section-slice-tsx" */),
  "slice---src-components-slices-elements-nav-flag-slice-tsx": () => import("./../../../src/components/slices/elements/NavFlagSlice.tsx" /* webpackChunkName: "slice---src-components-slices-elements-nav-flag-slice-tsx" */),
  "slice---src-components-slices-mf-footer-mf-footer-slice-tsx": () => import("./../../../src/components/slices/MFFooter/MFFooterSlice.tsx" /* webpackChunkName: "slice---src-components-slices-mf-footer-mf-footer-slice-tsx" */),
  "slice---src-components-slices-navigation-navigation-slice-tsx": () => import("./../../../src/components/slices/navigation/NavigationSlice.tsx" /* webpackChunkName: "slice---src-components-slices-navigation-navigation-slice-tsx" */),
  "slice---src-components-slices-oc-applications-oc-applications-slice-tsx": () => import("./../../../src/components/slices/OCApplications/OCApplicationsSlice.tsx" /* webpackChunkName: "slice---src-components-slices-oc-applications-oc-applications-slice-tsx" */),
  "slice---src-components-slices-oc-explore-the-range-oc-explore-the-range-slice-tsx": () => import("./../../../src/components/slices/OCExploreTheRange/OCExploreTheRangeSlice.tsx" /* webpackChunkName: "slice---src-components-slices-oc-explore-the-range-oc-explore-the-range-slice-tsx" */),
  "slice---src-components-slices-oc-flavour-diagram-oc-flavour-diagram-slice-tsx": () => import("./../../../src/components/slices/OCFlavourDiagram/OCFlavourDiagramSlice.tsx" /* webpackChunkName: "slice---src-components-slices-oc-flavour-diagram-oc-flavour-diagram-slice-tsx" */),
  "slice---src-components-slices-oc-flavour-pairings-oc-flavour-pairings-slice-tsx": () => import("./../../../src/components/slices/OCFlavourPairings/OCFlavourPairingsSlice.tsx" /* webpackChunkName: "slice---src-components-slices-oc-flavour-pairings-oc-flavour-pairings-slice-tsx" */),
  "slice---src-components-slices-oc-footer-oc-footer-slice-tsx": () => import("./../../../src/components/slices/OCFooter/OCFooterSlice.tsx" /* webpackChunkName: "slice---src-components-slices-oc-footer-oc-footer-slice-tsx" */),
  "slice---src-components-slices-oc-product-page-banner-oc-product-page-banner-slice-tsx": () => import("./../../../src/components/slices/OCProductPageBanner/OCProductPageBannerSlice.tsx" /* webpackChunkName: "slice---src-components-slices-oc-product-page-banner-oc-product-page-banner-slice-tsx" */),
  "slice---src-components-slices-oc-product-tabs-oc-small-bag-tabs-slice-tsx": () => import("./../../../src/components/slices/OCProductTabs/OCSmallBagTabsSlice.tsx" /* webpackChunkName: "slice---src-components-slices-oc-product-tabs-oc-small-bag-tabs-slice-tsx" */),
  "slice---src-components-slices-oc-video-banner-oc-video-banner-slice-tsx": () => import("./../../../src/components/slices/OCVideoBanner/OCVideoBannerSlice.tsx" /* webpackChunkName: "slice---src-components-slices-oc-video-banner-oc-video-banner-slice-tsx" */)
}

